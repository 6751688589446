import { FC } from "react";
import { useParams } from "react-router-dom";
import { useDoc } from "../../../hooks/useDoc";
import background from "../image/back-5_2.png";
import { NazoClusterQuizRoom, NazoClusterQuizUser } from "../types";
import { Stage1stPlayer } from "./components/Stage1stPlayer";
import { Stage2ndPlayer } from "./components/Stage2ndPlayer";
import { Stage3rdPlayer } from "./components/Starge3rdPlayer";
import { StageFinalPlayer } from "./components/StageFinalPlayer";
import { Stage4thPlayer } from "./components/Starge4thPlayer";
import { ResultsPlayer } from "./components/ResultsPlayer";
import { RegisterPlayer } from "./components/RegisterPlayer";

export type StagePlayerProps = {
  room: NazoClusterQuizRoom;
  user: NazoClusterQuizUser;
  roomId: string;
  userId?: string;
};

export const NazoClusterQuizPlayer: FC = () => {
  const { roomId, hash } = useParams<{ roomId: string; hash: string }>();
  const [room, setRoom] = useDoc<NazoClusterQuizRoom>({
    path: "nazoClusterQuiz/" + roomId,
    initialData: {
      mode: "waiting",
      setId: "default",
    },
  });

  /*
  const [roomSet] = useDoc<NazoClusterQuizSet>({
    path: room ? "sets/" + room.setId : null,
  });*/
  const roomSet = null;
  const [userData, setUserData] = useDoc<NazoClusterQuizUser>({
    path: "nazoClusterQuiz/" + roomId + "/user/" + hash,
  });

  console.log(room);

  if (!room || !userData || !roomId) {
    return <></>;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        minHeight: "100dvh",
      }}
    >
      {room.mode === "register" && (
        <RegisterPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "1st" && (
        <Stage1stPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "2nd" && (
        <Stage2ndPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "3rd" && (
        <Stage3rdPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "4th" && (
        <Stage4thPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "final" && (
        <StageFinalPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
      {room.mode === "results" && (
        <ResultsPlayer
          room={room}
          user={userData}
          roomId={roomId}
          userId={hash}
        />
      )}
    </div>
  );
};
