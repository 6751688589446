import { Grid } from "@mui/material";
import { TeamArea } from "../common/TeamArea";
import { teams } from "../NazoClusterQuizScreen";
import { NazoClusterQuizRoom, NazoClusterQuizUser } from "../../types";
import { useMemo } from "react";
import { Mode } from "../common/MemberArea";
import { WithId } from "../../../../hooks/useCollection";
import { getProblem3rd } from "../../data/prob";

export const Stage3rdScreen = ({
  room,
  users,
}: {
  room: NazoClusterQuizRoom;
  users: WithId<NazoClusterQuizUser>[];
}) => {
  const stage3 = useMemo(() => room.stage3, [room.stage3]);
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );

  const playerIds = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.id ?? ""
        )
      ),
    [room.teams]
  );
  const playerAnswers = useMemo(
    () =>
      playerIds.map((team) =>
        team.map((id) => {
          const probNumber = room.stage3?.probNumber ?? 1;
          const answer = users.find((user) => user.id === id)?.stage3?.[
            probNumber
          ];
          if (answer === undefined || !answer.open) {
            return "";
          }
          return answer.answer;
        })
      ),
    [playerIds, room.stage3, users]
  );
  const playerColors: Mode[][] = useMemo(
    () =>
      playerIds.map((team) => {
        const correctStatus = team.map((id) => {
          const user = users.find((user) => user.id === id);
          if (!user) {
            return -1;
          }
          const userSubmit = stage3?.probNumber
            ? user?.stage3?.[stage3?.probNumber]
            : undefined;
          if (userSubmit === undefined) {
            return -1;
          }
          const prob = getProblem3rd(stage3?.probNumber ?? 1);
          const answer = prob?.answer ?? "";
          return (
            userSubmit.correctStatus ??
            answer.split(",").findIndex((ans) => ans === userSubmit.answer)
          );
        });
        console.log(correctStatus);
        return team.map((id, i) => {
          const user = users.find((user) => user.id === id);
          if (
            !stage3?.probNumber ||
            !user?.stage3?.[stage3?.probNumber]?.open
          ) {
            return "normal";
          }
          const status = correctStatus[i];
          if (status === -1) {
            return "blue";
          }
          return correctStatus.filter((s) => s === status).length === 1
            ? "red"
            : "green";
        });
      }),
    [playerIds, stage3, users]
  );

  const totalScores = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) => room?.stage3?.team?.[teamId]?.score ?? 0),
    [room.stage3]
  );

  //TODO 色の設定
  return (
    <Grid container>
      {teams.map((team, i) => (
        <Grid item xs={6} key={i}>
          <TeamArea
            teamName={team.teamName}
            teamColor={team.teamColor}
            playerNames={names[i]}
            playerColors={playerColors[i]}
            playerAnswers={playerAnswers[i]}
            totalScore={totalScores[i]}
          />
        </Grid>
      ))}
    </Grid>
  );
};
