import { useMemo } from "react";
import { teamName } from "../../data/teamName";
import { blue, green, red, yellow } from "../../theme";
import { NazoClusterQuizRoom } from "../../types";

export const ResultsScreen = ({ room }: { room: NazoClusterQuizRoom }) => {
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );

  return (
    <div
      style={{
        padding: "5vh 0",
      }}
    >
      <table
        style={{
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
          margin: "auto",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}></th>
          <th style={thStyle}>ラウンド1</th>
          <th style={thStyle}>ラウンド2</th>
          <th style={thStyle}>ラウンド3</th>
          <th style={thStyle}>ラウンド4</th>
          <th style={thStyle}>最終結果</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => {
          const stage1Total =
            Object.values(room?.stage1?.team[teamId] ?? {}).reduce(
              (acc, cur) => acc + cur.score,
              0
            ) ?? 0;
          const stage2Total = room?.stage2?.team[teamId]?.score ?? 0;
          const stage3Total = room?.stage3?.team[teamId]?.score ?? 0;
          const stage4Total = room?.stage4?.team[teamId]?.score ?? 0;

          const teamMembers = names[teamId - 1];
          if (teamMembers.every((name) => name === "")) {
            return null;
          }

          return (
            <tr key={teamId}>
              <th
                style={{
                  ...thStyle,
                  ...teamHeaderStyle,
                  backgroundColor: [red, blue, green, yellow][teamId - 1],
                }}
              >
                {teamName[teamId]}
              </th>

              <td
                style={{
                  ...tdScoreStyle,
                }}
              >
                <span
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  {stage1Total}
                </span>
                点
              </td>
              <td
                style={{
                  ...tdScoreStyle,
                }}
              >
                <span
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  {stage2Total - stage1Total}
                </span>
                点
              </td>
              <td
                style={{
                  ...tdScoreStyle,
                }}
              >
                <span
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  {stage3Total - stage2Total}
                </span>
                点
              </td>
              <td
                style={{
                  ...tdScoreStyle,
                }}
              >
                <span
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  {stage4Total - stage3Total}
                </span>
                点
              </td>
              <td
                style={{
                  ...tdScoreStyle,
                }}
              >
                <span
                  style={{
                    fontSize: "1.8em",
                  }}
                >
                  {room?.stageFinal?.team[teamId]?.rank ?? 0}
                </span>
                位
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const trStyle = {
  border: "1px solid #000",
  fontSize: "1em",
  height: "8vh",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
  fontSize: "1.2em",
  padding: "0 1em",
};

const tdScoreStyle = {
  height: "15vh",
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
  textAlign: "right" as const,
  backgroundColor: "#fff",
  fontSize: "1.5em",
  padding: "0 1em",
};

const teamHeaderStyle = {
  minWidth: "100px",
  fontSize: "1.5em",
  color: "white",
};
