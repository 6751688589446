import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { MemberArea, Mode } from "./MemberArea";
import CountUp from "react-countup";

type TeamScreenProps = {
  teamName: string;
  teamColor: string;
  playerNames: string[];
  playerColors: Mode[];
  playerAnswers: string[];
  answerTimeMs?: (number | undefined)[];
  totalScore: number;
};

export const TeamArea: FC<TeamScreenProps> = (props) => {
  const { teamName, teamColor, totalScore } = props;
  const [prevTotalScore, setPrevTotalScore] = useState(totalScore);
  const [currentTotalScore, setCurrentTotalScore] = useState(totalScore);
  useEffect(() => {
    if (currentTotalScore !== totalScore) {
      setPrevTotalScore(currentTotalScore);
      setCurrentTotalScore(totalScore);
    }
  }, [totalScore]);
  if (props.playerNames.every((name) => name === "")) {
    return (
      <div
        style={{
          margin: "2vw",
          height: "40vh",
          width: "46vw",
        }}
      />
    );
  }
  return (
    <div
      style={{
        margin: "2vw",
        height: "40vh",
        width: "46vw",
      }}
    >
      <Grid container>
        <Grid item xs={7}>
          <div
            style={{
              height: "10vh",
              border: `0.5vw solid ${teamColor}`,
              margin: "0 1vw",
              backgroundColor: teamColor,
              color: "white",
            }}
          >
            <h1>{teamName}</h1>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div
            style={{
              height: "10vh",
              border: `0.5vw solid ${teamColor}`,
              backgroundColor: "white",
              margin: "0 1vw",
            }}
          >
            <h1
              style={{
                textAlign: "right",
                verticalAlign: "bottom",
                marginLeft: "auto",
                paddingLeft: "auto",
                marginRight: "1vw",
                marginBottom: "0",
              }}
            >
              <CountUp
                start={prevTotalScore}
                end={currentTotalScore}
                duration={0.5}
              />
              {/*totalScore*/}{" "}
              <span
                style={{
                  // 小さくして右下に寄せて表示
                  fontSize: "0.5em",
                }}
              >
                点
              </span>
            </h1>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ my: "2vh" }}>
        {props.playerNames.map((name, i) => (
          <Grid item xs={4} key={i}>
            <MemberArea
              mode={props.playerColors[i]}
              name={name}
              answer={props.playerAnswers[i]}
              answerTimeMs={props.answerTimeMs?.[i]}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
