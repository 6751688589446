import { Box } from "@mui/material";
import { FC } from "react";
import { teamName } from "../../data/teamName";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

export const Results: FC<StageProps> = (props) => {
  const { room } = props;
  const stageFinal = room.stageFinal;

  if (!stageFinal) {
    return <Box>loading...</Box>;
  }

  return (
    <>
      <h2>最終結果</h2>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>ステージ1</th>
          <th style={thStyle}>ステージ2</th>
          <th style={thStyle}>ステージ3</th>
          <th style={thStyle}>ステージ4</th>
          <th style={thStyle}>最終結果</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => {
          const stage1Total =
            Object.values(room?.stage1?.team[teamId] ?? {}).reduce(
              (acc, cur) => acc + cur.score,
              0
            ) ?? 0;
          const stage2Total = room?.stage2?.team[teamId]?.score ?? 0;
          const stage3Total = room?.stage3?.team[teamId]?.score ?? 0;
          const stage4Total = room?.stage4?.team[teamId]?.score ?? 0;
          return (
            <tr key={teamId}>
              <th
                style={{
                  ...thStyle,
                  ...teamHeaderStyle,
                  backgroundColor: [red, blue, green, yellow][teamId - 1],
                }}
              >
                {teamName[teamId]}
              </th>
              <td
                style={{
                  ...tdStyle,
                }}
              >
                {stage1Total}点
              </td>
              <td
                style={{
                  ...tdStyle,
                }}
              >
                {stage2Total - stage1Total}点
              </td>
              <td
                style={{
                  ...tdStyle,
                }}
              >
                {stage3Total - stage2Total}点
              </td>
              <td
                style={{
                  ...tdStyle,
                }}
              >
                {stage4Total - stage3Total}点
              </td>
              <td
                style={{
                  ...tdStyle,
                }}
              >
                {room?.stageFinal?.team[teamId]?.rank ?? 0}位
              </td>
            </tr>
          );
        })}
      </table>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
