import { Grid } from "@mui/material";
import { TeamArea } from "../common/TeamArea";
import { teams } from "../NazoClusterQuizScreen";
import { NazoClusterQuizRoom, NazoClusterQuizUser } from "../../types";
import { useMemo } from "react";
import { Mode } from "../common/MemberArea";
import { WithId } from "../../../../hooks/useCollection";
import { getProblem1st } from "../../data/prob";

export const Stage1stScreen = ({
  room,
  users,
}: {
  room: NazoClusterQuizRoom;
  users: WithId<NazoClusterQuizUser>[];
}) => {
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );

  const playerIds = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.id ?? ""
        )
      ),
    [room.teams]
  );

  console.log(playerIds);
  const stage1 = room.stage1;
  const playerAnswers = playerIds.map((team) =>
    team.map((id) => {
      const user = users.find((user) => user.id === id);
      if (!user) {
        return "";
      }
      console.log(users, id);
      console.log(stage1, user?.stage1);
      const userSubmit =
        stage1?.genre && stage1?.probNumber
          ? user?.stage1?.[stage1?.genre]?.[stage1?.probNumber]
          : undefined;
      if (userSubmit === undefined || !userSubmit.open) {
        return "";
      }
      console.log(userSubmit.answer);
      return userSubmit.answer;
    })
  );

  const playerColors: Mode[][] = playerIds.map((team) =>
    team.map((id) => {
      const user = users.find((user) => user.id === id);
      if (!user) {
        return "normal";
      }
      const userSubmit =
        stage1?.genre && stage1?.probNumber
          ? user?.stage1?.[stage1?.genre]?.[stage1?.probNumber]
          : undefined;
      if (userSubmit === undefined || !userSubmit.open) {
        return "normal";
      }
      const prob = getProblem1st(
        stage1?.probNumber ?? 1,
        user.teamNumber ?? 1,
        stage1?.genre ?? "ジャンル：国語"
      );
      const answer = prob?.answer ?? "";
      return userSubmit.correct ?? answer === userSubmit.answer
        ? "red"
        : "blue";
    })
  );

  const totalScores = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        Object.values(stage1?.team?.[teamId] ?? {}).reduce(
          (acc, value) => acc + value.score,
          0
        )
      ),
    [room.stage1]
  );
  //TODO 色の設定
  return (
    <Grid container>
      {teams.map((team, i) => (
        <Grid item xs={6} key={i}>
          <TeamArea
            teamName={team.teamName}
            teamColor={team.teamColor}
            playerNames={names[i]}
            playerColors={playerColors[i]}
            playerAnswers={playerAnswers[i]}
            totalScore={totalScores[i]}
          />
        </Grid>
      ))}
    </Grid>
  );
};
