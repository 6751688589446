import { Grid } from "@mui/material";
import { doc, serverTimestamp, Timestamp, updateDoc } from "firebase/firestore";
import { CSSProperties, FC, useEffect, useState } from "react";
import CountUp from "react-countup";
import { db } from "../../../..";
import { getServerTime } from "../../../../lib/getServerTime";
import { getProblemFinal } from "../../data/prob";
import { teamName } from "../../data/teamName";
import { blue, green, mainBg, mainColor, red, yellow } from "../../theme";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";

export const StageFinalPlayer: FC<StagePlayerProps> = (props) => {
  const { room, user, roomId, userId } = props;

  const stageFinal = room?.stageFinal;
  const prob = getProblemFinal(stageFinal?.probNumber ?? 1);

  const [serverTimeDiff, setServerTimeDiff] = useState<number>(0);
  const playerName = user?.name ?? "";
  const [serverTime, setServerTime] = useState<Date>();
  const start = stageFinal?.phase !== "waiting";

  const explained =
    stageFinal?.phase === "explaining" || stageFinal?.phase === "calculated";
  const finished =
    stageFinal?.phase === "finished" ||
    stageFinal?.phase === "explaining" ||
    stageFinal?.phase === "calculated";

  useEffect(() => {
    if (!room || !user) {
      return;
    }
    if (!start) {
      return;
    }
    if (!playerName) {
      return;
    }
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start, playerName]);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // phase: waiting時に初期化
    if (!start) {
      setSubmitted(false);
    }
  }, [start]);

  const submit = (input: string) => {
    if (!roomId) return;
    if (!user) return;
    if (!prob) return;
    if (!start) return;
    if (finished) return;
    if (!serverTime) return;
    if (!userId) return;
    setSubmitted(true);
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stageFinal." + stageFinal?.probNumber]: {
        answer: input,
        answerTime: serverTimestamp(),
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "8vw",
              fontWeight: "bold",
            }}
          >
            {prob?.problemNumberText}
          </p>
        </div>
      </Grid>

      <Grid item xs={8}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          <p
            style={{
              fontSize: "6vw",
              fontWeight: "bold",
              marginLeft: "2vw",
              marginRight: "auto",
            }}
          >
            {prob?.genre}
          </p>
        </div>
      </Grid>
      {[1, 2, 3, 4].map((teamId) => {
        const teamMembers = [1, 2, 3].filter(
          (i) => (room.teams?.[teamId]?.number?.[i]?.name ?? "") !== ""
        );
        if (teamMembers.length === 0) {
          return <Grid item xs={3} key={teamId} />;
        }
        return (
          <Grid item xs={3} key={teamId}>
            <div
              style={{
                backgroundColor: [red, blue, green, yellow][teamId - 1],
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                {teamName[teamId]}
              </p>
            </div>
            <div
              style={{
                backgroundColor: "white",
                color:
                  (room.stageFinal?.team[teamId].score ?? 0) <= 20
                    ? "red"
                    : "black",
                fontWeight: "bold",
                border: "1vw solid #000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                <Score totalScore={room.stageFinal?.team[teamId].score ?? 0} />
              </p>
            </div>
          </Grid>
        );
      })}
      <Grid
        item
        xs={12}
        sx={{
          my: 3,
        }}
      />
      {!start && (
        <Grid item xs={12}>
          <div
            style={{
              width: "calc(100% - 4vw)",
              margin: "2vw",
              borderRadius: "2vw",
              backgroundColor: mainBg,
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/nazo-cluster-quiz/final/" +
                prob?.introFileName
              }
              style={{
                width: "calc(100% - 4vw)",
                margin: "2vw",
              }}
              alt="問題画像"
            />
          </div>
        </Grid>
      )}
      {start && !finished && (
        <>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("A")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: red,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "A"
                    ? 0.5
                    : 1,
              }}
            >
              A
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("B")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: blue,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "B"
                    ? 0.5
                    : 1,
              }}
            >
              B
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("C")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: green,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "C"
                    ? 0.5
                    : 1,
              }}
            >
              C
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => !finished && !submitted && submit("D")}
          >
            <div
              style={{
                ...choiceBox,
                backgroundColor: yellow,
                opacity:
                  (finished || submitted) &&
                  user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer !==
                    "D"
                    ? 0.5
                    : 1,
              }}
            >
              D
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              color: mainColor,
              fontSize: "2vw",
              fontWeight: "bold",
              textAlign: "center",
              margin: "2vw 0",
            }}
          >
            <p>一度タップすると変更できません</p>
          </Grid>
        </>
      )}

      {start && !explained && (
        <Grid
          item
          xs={12}
          sx={{
            color: mainColor,
            fontSize: "2vw",
            fontWeight: "bold",
            textAlign: "center",
            margin: "2vw 0",
          }}
        >
          <p>
            {submitted || finished
              ? "解答判定をお待ちください"
              : prob?.answerMessage}
          </p>
        </Grid>
      )}
      {explained && (
        <table style={{ borderSpacing: 0, background: "#fff", margin: "auto" }}>
          {(stageFinal.problem?.[stageFinal.probNumber ?? "1"]?.answers ?? [])
            .map((userAns) => {
              return {
                user: room.teams?.[userAns?.teamId ?? 0]?.number?.[
                  userAns?.teamNumber ?? 0
                ],
                userAns: userAns,
              };
            })
            .sort(({ userAns: a }, { userAns: b }) => {
              return !a?.answerTime && !b?.answerTime
                ? 0
                : !a?.answerTime
                ? 1
                : !b?.answerTime
                ? -1
                : (a?.answerTime as Timestamp)?.toDate().getTime() -
                  (b?.answerTime as Timestamp)?.toDate().getTime();
            })
            .map(({ user, userAns: userAns }, i) => {
              const startTime = (stageFinal?.startTime as Timestamp | undefined)
                ?.toDate()
                .getTime();
              const answerTime = (userAns?.answerTime as Timestamp | undefined)
                ?.toDate()
                .getTime();

              const ansIndex = ["A", "B", "C", "D"].indexOf(
                userAns?.answer ?? ""
              );
              if (!user) return null;
              return (
                <tr key={user.name} style={{ border: "1px solid #000" }}>
                  <th style={{ width: "35px", border: "1px solid #000" }}>
                    {answerTime ? i + 1 : "---"}位
                  </th>
                  <td
                    style={{
                      backgroundColor: [red, blue, green, yellow][
                        (userAns.teamId ?? 0) - 1
                      ],
                      color: "white",
                      width: "68px",
                      border: "1px solid #000",
                    }}
                  >
                    {teamName[userAns.teamId ?? 0]}
                  </td>
                  <td style={{ border: "1px solid #000", fontSize: "0.9em" }}>
                    {user.name}
                  </td>
                  <td
                    style={{
                      width: "35px",
                      border: "1px solid #000",
                      textAlign: "center",
                      backgroundColor:
                        (i === 0 && stageFinal.openFastest) ||
                        (i !== 0 && stageFinal.open)
                          ? [red, blue, green, yellow][ansIndex]
                          : "#fff",
                      color:
                        (i === 0 && stageFinal.openFastest) ||
                        (i !== 0 && stageFinal.open)
                          ? "white"
                          : "black",
                    }}
                  >
                    {answerTime ? (
                      (i === 0 && stageFinal.openFastest) ||
                      (i !== 0 && stageFinal.open) ? (
                        <span>{userAns?.answer}</span>
                      ) : (
                        <span>？</span>
                      )
                    ) : (
                      <span style={{ color: "black" }}>---</span>
                    )}
                  </td>
                  <td
                    style={{
                      width: "60px",
                      border: "1px solid #000",
                      textAlign: "right",
                    }}
                  >
                    {startTime && answerTime ? (
                      <span>
                        {" "}
                        {((answerTime - startTime) / 1000) | 0}.
                        {(
                          "00" +
                          ((((answerTime - startTime) % 1000) / 10) | 0)
                        ).slice(-2)}
                        秒
                      </span>
                    ) : (
                      "--- 秒"
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
      )}
    </Grid>
  );
};

const Score = (props: { totalScore: number }) => {
  const { totalScore } = props;
  const [prevTotalScore, setPrevTotalScore] = useState(totalScore);
  const [currentTotalScore, setCurrentTotalScore] = useState(totalScore);
  const [retired, setRetired] = useState(false);
  useEffect(() => {
    if (currentTotalScore !== totalScore) {
      setPrevTotalScore(currentTotalScore);
      setCurrentTotalScore(totalScore);
    }
    if (totalScore === 0) {
      setTimeout(() => {
        // 脱落時に0まで減ってから脱落表示にする
        setRetired(true);
      }, 400);
    } else {
      setRetired(false);
    }
  }, [totalScore]);

  return retired ? (
    <>脱落</>
  ) : (
    <CountUp start={prevTotalScore} end={currentTotalScore} duration={0.5} />
  );
};

const choiceBox: CSSProperties = {
  width: "calc(100% - 4vw)",
  height: "36vw",
  margin: "2vw",
  borderRadius: "2vw",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  textAlign: "center",
  fontSize: "20vw",
};
