import {
  Box,
  Button,
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useCollection, WithId } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import {
  NazoClusterQuizRoom,
  NazoClusterQuizSet,
  NazoClusterQuizUser,
  RoomTeams,
} from "../types";
import { UserRegister } from "./components/Register";
import { Stage1st } from "./components/Stage1st";
import { Stage2nd } from "./components/Stage2nd";
import { Stage3rd } from "./components/Stage3rd";
import { Stage4th } from "./components/Stage4th";
import { StageFinal } from "./components/StageFinal";
import { Results } from "./components/Results";
import { useState } from "react";
import Modal from "react-modal";

const steps = [
  "準備",
  "1st	スリーリーグ",
  "2nd	ドボンクイズ",
  "3rd	スリーアンサークイズ",
  "4th	解答権奪取クイズ",
  "決勝 全滅4択クイズ",
  "結果",
];

const stepIds = ["register", "1st", "2nd", "3rd", "4th", "final", "results"];

export type StageProps = {
  users: WithId<NazoClusterQuizUser>[];
  roomId: string;
  room: NazoClusterQuizRoom;
};

export const AdminNazoClusterQuizGame: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const [room, setRoom] = useDoc<NazoClusterQuizRoom>({
    path: "nazoClusterQuiz/" + roomId,
    initialData: {
      mode: "waiting",
      setId: "default",
    },
  });

  const [roomSet] = useDoc<NazoClusterQuizSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const { docList: usersDoc, dataList: users } =
    useCollection<NazoClusterQuizUser>({
      path: roomId ? "nazoClusterQuiz/" + roomId + "/user" : null,
    });

  const initializeStep = (mode: string) => {
    if (mode === "1st") {
      return {
        stage1: {
          genre: "ジャンル：国語",
          probNumber: 1,
          phase: "waiting",
          genres: {},
          team: {
            1: {},
            2: {},
            3: {},
            4: {},
          },
        },
        teams: getTeams(users),
      };
    }
    if (mode === "2nd") {
      const teamScores = [1, 2, 3, 4].map((teamId) =>
        Object.values(room?.stage1?.team?.[teamId] ?? {}).reduce(
          (acc, value) => acc + value.score,
          0
        )
      );
      return {
        stage2: {
          probNumber: 1,
          phase: "waiting",
          team: {
            1: {
              score: teamScores[0],
              initialScore: teamScores[0],
            },
            2: {
              score: teamScores[1],
              initialScore: teamScores[1],
            },
            3: {
              score: teamScores[2],
              initialScore: teamScores[2],
            },
            4: {
              score: teamScores[3],
              initialScore: teamScores[3],
            },
          },
        },
      };
    }
    if (mode === "3rd") {
      return {
        stage3: {
          probNumber: 1,
          phase: "waiting",
          team: {
            "1": {
              score: room?.stage2?.team?.[1]?.score ?? 0,
              initialScore: room?.stage2?.team?.[1]?.score ?? 0,
            },
            "2": {
              score: room?.stage2?.team?.[2]?.score ?? 0,
              initialScore: room?.stage2?.team?.[2]?.score ?? 0,
            },
            "3": {
              score: room?.stage2?.team?.[3]?.score ?? 0,
              initialScore: room?.stage2?.team?.[3]?.score ?? 0,
            },
            "4": {
              score: room?.stage2?.team?.[4]?.score ?? 0,
              initialScore: room?.stage2?.team?.[4]?.score ?? 0,
            },
          },
        },
      };
    }
    if (mode === "4th") {
      return {
        stage4: {
          probNumber: 1,
          phase: "waiting",
          team: {
            1: {
              score: room?.stage3?.team?.[1]?.score ?? 0,
              initialScore: room?.stage3?.team?.[1]?.score ?? 0,
            },
            2: {
              score: room?.stage3?.team?.[2]?.score ?? 0,
              initialScore: room?.stage3?.team?.[2]?.score ?? 0,
            },
            3: {
              score: room?.stage3?.team?.[3]?.score ?? 0,
              initialScore: room?.stage3?.team?.[3]?.score ?? 0,
            },
            4: {
              score: room?.stage3?.team?.[4]?.score ?? 0,
              initialScore: room?.stage3?.team?.[4]?.score ?? 0,
            },
          },
        },
      };
    }
    if (mode === "final") {
      return {
        stageFinal: {
          probNumber: 1,
          phase: "waiting",
          team: {
            1: {
              score: room?.stage4?.team?.[1]?.score ?? 0,
              initialScore: room?.stage4?.team?.[1]?.score ?? 0,
            },
            2: {
              score: room?.stage4?.team?.[2]?.score ?? 0,
              initialScore: room?.stage4?.team?.[2]?.score ?? 0,
            },
            3: {
              score: room?.stage4?.team?.[3]?.score ?? 0,
              initialScore: room?.stage4?.team?.[3]?.score ?? 0,
            },
            4: {
              score: room?.stage4?.team?.[4]?.score ?? 0,
              initialScore: room?.stage4?.team?.[4]?.score ?? 0,
            },
          },
        },
      };
    }

    return {};
  };
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);
  const reset = () => {
    if (!roomId || !room) {
      return;
    }
    setRoom({
      ...room,
      mode: "register",
    });
    setOpenResetModal(false);
  };

  if (!roomId || !room) {
    return <></>;
  }

  return (
    <>
      <h1>ナゾクラクイズ管理画面</h1>

      <Box
        sx={{
          width: "100%",
          fontSize: "100%",
        }}
      >
        <Stepper
          activeStep={stepIds.findIndex((id) => id === room.mode)}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ my: 1 }} />
        <Container
          sx={{
            maxWidth: 1200,
            mx: "auto",
          }}
        >
          {room.mode === "register" && (
            <UserRegister users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "1st" && (
            <Stage1st users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "2nd" && (
            <Stage2nd users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "3rd" && (
            <Stage3rd users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "4th" && (
            <Stage4th users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "final" && (
            <StageFinal users={users} roomId={roomId} room={room} />
          )}
          {room.mode === "results" && (
            <Results users={users} roomId={roomId} room={room} />
          )}
          <Box sx={{ my: 4 }} />
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setOpenResetModal(true)}
          >
            リセット
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={room.mode === "results"}
            sx={{
              ml: 2,
            }}
            onClick={() => {
              if (room.mode === "results") {
                return;
              }
              const nextMode =
                stepIds[stepIds.findIndex((id) => id === room.mode) + 1];
              const step = initializeStep(nextMode);
              setRoom({
                ...room,
                mode: nextMode,
                ...step,
              });
            }}
          >
            次へ
          </Button>
          <Box sx={{ my: 8 }} />
          <Modal
            isOpen={openResetModal}
            onRequestClose={() => setOpenResetModal(false)}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <>
              <h2>進行状況リセット</h2>
              <p>本当に進行状況をリセットしますか？</p>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mt: 2 }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => setOpenResetModal(false)}
                  sx={{ mr: 1 }}
                >
                  キャンセル
                </Button>
                <Button variant="contained" onClick={reset}>
                  リセット
                </Button>
              </Stack>
            </>
          </Modal>
        </Container>
      </Box>
    </>
  );
};

export const getTeams = (users: WithId<NazoClusterQuizUser>[]) => {
  const teams: RoomTeams = {
    1: {
      number: {
        1:
          users
            .filter((u) => u.teamId === 1 && u.teamNumber === 1)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        2:
          users
            .filter((u) => u.teamId === 1 && u.teamNumber === 2)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        3:
          users
            .filter((u) => u.teamId === 1 && u.teamNumber === 3)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
      },
    },
    2: {
      number: {
        1:
          users
            .filter((u) => u.teamId === 2 && u.teamNumber === 1)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        2:
          users
            .filter((u) => u.teamId === 2 && u.teamNumber === 2)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        3:
          users
            .filter((u) => u.teamId === 2 && u.teamNumber === 3)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
      },
    },
    3: {
      number: {
        1:
          users
            .filter((u) => u.teamId === 3 && u.teamNumber === 1)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        2:
          users
            .filter((u) => u.teamId === 3 && u.teamNumber === 2)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        3:
          users
            .filter((u) => u.teamId === 3 && u.teamNumber === 3)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
      },
    },
    4: {
      number: {
        1:
          users
            .filter((u) => u.teamId === 4 && u.teamNumber === 1)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        2:
          users
            .filter((u) => u.teamId === 4 && u.teamNumber === 2)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
        3:
          users
            .filter((u) => u.teamId === 4 && u.teamNumber === 3)
            .map((u) => ({
              id: u.id,
              name: u.name,
            }))?.[0] ?? null,
      },
    },
  };
  return teams;
};
