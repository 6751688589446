import { Box, Card, Stack } from "@mui/material";
import QRCode from "qrcode.react";

export const Register = ({ roomId }: { roomId: string }) => {
  const url = `${window.location.protocol}//${window.location.hostname}/nazo-cluster-quiz/${roomId}`;
  return (
    <Box sx={{ pt: 10 }}>
      <Card variant="outlined" sx={{ mx: "auto", p: 5, width: "950px" }}>
        <Box
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ py: "100px" }}>
            <QRCode value={url} size={400} />
          </Box>
          <Box>
            <img
              src={"/images/nazo-cluster-quiz/qr_explanation.png"}
              alt="qr-explanation"
              style={{ height: 600 }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
