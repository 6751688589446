import { Grid } from "@mui/material";
import { FC } from "react";
import background from "../../image/back-5_2.png";
import { teams } from "../../screen/NazoClusterQuizScreen";
import { TeamSelector } from "../common/TeamSelector";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";

export const Stage2ndPlayer: FC<StagePlayerProps> = (props) => {
  const { room, user, roomId, userId } = props;
    const stage2 = room?.stage2;
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        height: "100dvh",
      }}
    >
      <Grid container>
        {teams.map((team, i) => (
          <Grid item xs={12} key={i}>
            <TeamSelector
              teamName={team.teamName}
              teamColor={team.teamColor}
              teamId={i + 1}
              {...props}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
