import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { hashString } from "react-hash-string";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../index";

const makeHash = (playerName: string) => {
  let ret = "";
  ret += String(Math.abs(hashString(playerName)));
  while (ret.length < 10) ret += "0";
  let EmanReyalp = playerName;
  EmanReyalp = EmanReyalp.split("").reverse().join("");
  ret += String(Math.abs(hashString(EmanReyalp)));
  while (ret.length < 20) ret += "0";
  return ret;
};

export const NazoClusterQuizRoomInWithId: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const [playerName, setPlayerName] = React.useState<string>(
    window.localStorage.getItem("nazo-cluster-quiz-player-name") ?? ""
  );
  const [modalMessage, setModalMessage] = React.useState<string>("");
  const navigate = useNavigate();

  const nameValidation = (name: string) => {
    //ひらがな片仮名10文字以内
    return name.match(/^[ぁ-んァ-ヶー]{1,10}$/);
  };

  const [openNameCheckModal, setOpenNameCheckModal] = useState(false);

  const intoRoom = () => {
    if (playerName === "") return;
    if (!nameValidation(playerName)) {
      setModalMessage(
        "名前は10文字以内(ひらがな または カタカナ)で入力してください"
      );
      return;
    }
    if (!roomId) return;
    const hash = makeHash(playerName);
    (async () => {
      const roomDocSnap = await getDoc(doc(db, "nazoClusterQuiz", roomId));
      if (!roomDocSnap.exists()) {
        setModalMessage("存在しないルームIDです");
        return;
      }
      const hashDocSnap = await getDoc(
        doc(db, "nazoClusterQuiz", roomId, "user", hash)
      );
      if (hashDocSnap.exists()) {
        if (hashDocSnap.data()?.name === playerName) {
          if (
            window.localStorage.getItem("nazo-cluster-quiz-player-name") ===
            playerName
          ) {
            navigate("/nazo-cluster-quiz/" + roomId + "/" + hash + "/game");
          } else {
            setOpenNameCheckModal(true);
          }
        } else {
          setModalMessage("その名前は使用できません");
          return;
        }
      } else {
        setDoc(doc(db, "nazoClusterQuiz", roomId, "user", hash), {
          name: playerName,
          teamId: null,
          teamNumber: null,
        });
        navigate("/nazo-cluster-quiz/" + roomId + "/" + hash + "/game");
      }
    })();
  };

  const intoRoomFromNameCheckModal = () => {
    // バリデーションは既に済んでいる
    const hash = makeHash(playerName);
    window.localStorage.setItem("nazo-cluster-quiz-player-name", playerName);
    navigate("/nazo-cluster-quiz/" + roomId + "/" + hash + "/game");
  };

  let subtitle: HTMLHeadingElement | null;

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalMessage("");
  }

  function closeNameCheckModal() {
    setOpenNameCheckModal(false);
  }

  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography variant="h3" sx={{ margin: "auto", my: 2 }}>
        入室
      </Typography>
      <br />
      <p>ID：{roomId}</p>
      <br />
      <TextField
        label="名前(10文字以内)"
        type="text"
        value={playerName}
        onChange={(e) => {
          setPlayerName(e.target.value);
        }}
        error={playerName !== "" && !nameValidation(playerName)}
        sx={{ mx: "auto", my: 5 }}
        inputProps={{ maxLength: 10 }}
        InputLabelProps={{ sx: { zIndex: "auto" } }}
      />
      <Typography>※ひらがな または カタカナで入力</Typography>
      <br />
      <Button
        onClick={intoRoom}
        variant={"contained"}
        size={"large"}
        sx={{ mx: "auto", my: 5 }}
      >
        エントリー
      </Button>
      <Modal
        isOpen={modalMessage !== ""}
        style={customStyles}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
      <Modal
        isOpen={openNameCheckModal}
        style={customStyles}
        onRequestClose={closeNameCheckModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          既に登録されている名前です
        </h2>
        <p>
          この名前を登録した本人の場合は、続行を押してログインしてください。
        </p>
        <p>それ以外の場合は、キャンセルを押して名前を変更してください。</p>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={closeNameCheckModal}
            sx={{ mr: 1 }}
          >
            キャンセル
          </Button>
          <Button variant="contained" onClick={intoRoomFromNameCheckModal}>
            続行
          </Button>
        </Stack>
      </Modal>
    </Container>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
