import { Grid } from "@mui/material";
import { TeamArea } from "../common/TeamArea";
import { teams } from "../NazoClusterQuizScreen";
import { NazoClusterQuizRoom } from "../../types";
import { useMemo } from "react";
import { Mode } from "../common/MemberArea";

export const Stage2ndScreen = ({ room }: { room: NazoClusterQuizRoom }) => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h3>お手元の端末をご覧ください。</h3>
    </div>
  );
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );
  const playerColors: Mode[] = ["red", "blue", "normal"];
  const playerAnswers = ["解答欄1", "解答欄2", "解答欄3"];
  return (
    <Grid container>
      {teams.map((team, i) => (
        <Grid item xs={6} key={i}>
          <TeamArea
            teamName={team.teamName}
            teamColor={team.teamColor}
            playerNames={names[i]}
            playerColors={playerColors}
            playerAnswers={playerAnswers}
            totalScore={135}
          />
        </Grid>
      ))}
    </Grid>
  );
};
