import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, IconButton } from "@mui/material";
import {
  deleteField,
  doc,
  increment,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { FC, useMemo } from "react";
import { db } from "../../../..";
import { getProblem4th } from "../../data/prob";
import { teamName } from "../../data/teamName";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

type ProbCursor = {
  genre: number;
  probNumber: number;
};

export const Stage4th: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;

  const stage4 = room.stage4;

  const fastest = useMemo(() => {
    const fastest: (
      | {
          userId: string;
          timestamp: Date;
        }
      | undefined
    )[] = [undefined, undefined, undefined, undefined];
    users.forEach((u) => {
      const prob = getProblem4th(stage4?.probNumber ?? 1);
      const answer = prob?.answer ?? [];
      const correctStatus = stage4?.probNumber
        ? u.stage4?.[stage4?.probNumber]?.correctStatus ??
          answer.findIndex((e) =>
            e.some(
              (ans) => ans === (u.stage4?.[stage4?.probNumber]?.answer ?? "")
            )
          )
        : undefined;

      const userSubmit = room.stage4?.probNumber
        ? u.stage4?.[room.stage4?.probNumber]
        : undefined;
      if (
        correctStatus !== undefined &&
        correctStatus !== -1 &&
        userSubmit !== undefined
      ) {
        if (fastest[correctStatus] === undefined) {
          fastest[correctStatus] = {
            userId: u.id,
            timestamp: userSubmit.answerTime?.toDate(),
          };
        } else {
          if (
            fastest[correctStatus]?.timestamp === undefined ||
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
            fastest[correctStatus]?.timestamp! > userSubmit.answerTime.toDate()
          ) {
            fastest[correctStatus] = {
              userId: u.id,
              timestamp: userSubmit.answerTime?.toDate(),
            };
          }
        }
      }
    });
    return fastest;
  }, [users, stage4?.probNumber, room.stage4?.probNumber]);

  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId,
      teamNumber,
    });
  };

  const updatePhase = (phase: string) => {
    if (!roomId) return;
    if (!room) return;
    if (phase === "waiting") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.phase": phase,
        "stage4.startTime": deleteField(),
        "stage4.endTime": deleteField(),
      });
    }

    if (phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.phase": phase,
        "stage4.startTime": serverTimestamp(),
      });
    }
    if (phase === "finished") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.phase": phase,
        "stage4.endTime": serverTimestamp(),
      });
    }
    if (phase === "explaining") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.phase": phase,
        ["stage4.team.1.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: 0,
        ["stage4.team.2.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: 0,
        ["stage4.team.3.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: 0,
        ["stage4.team.4.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: 0,
        "stage4.team.1.score": increment(
          -(stage4?.team[1]?.problem?.[stage4?.probNumber]?.score ?? 0)
        ),
        "stage4.team.2.score": increment(
          -(stage4?.team[2]?.problem?.[stage4?.probNumber]?.score ?? 0)
        ),
        "stage4.team.3.score": increment(
          -(stage4?.team[3]?.problem?.[stage4?.probNumber]?.score ?? 0)
        ),
        "stage4.team.4.score": increment(
          -(stage4?.team[4]?.problem?.[stage4?.probNumber]?.score ?? 0)
        ),
      });
    }
    if (phase === "calculated") {
      const fastestCount = [1, 2, 3, 4].map((teamId) => {
        let fastestCount = 0;
        users
          .filter((u) => u.teamId === teamId)
          .forEach((u) => {
            if (fastest.some((f) => f?.userId === u.id)) {
              fastestCount++;
            }
          });

        return fastestCount;
      });

      const defaultProb = getProblem4th(stage4?.probNumber ?? 1);
      const baseScore = defaultProb?.score ?? 0;

      console.log(fastestCount, baseScore);

      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.phase": phase,
        "stage4.calcTime": serverTimestamp(),

        ["stage4.team.1.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: fastestCount[0] * baseScore,
        ["stage4.team.2.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: fastestCount[1] * baseScore,
        ["stage4.team.3.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: fastestCount[2] * baseScore,
        ["stage4.team.4.problem." +
        (stage4?.probNumber ?? 1).toString() +
        ".score"]: fastestCount[3] * baseScore,
        "stage4.team.1.score": increment(fastestCount[0] * baseScore),
        "stage4.team.2.score": increment(fastestCount[1] * baseScore),
        "stage4.team.3.score": increment(fastestCount[2] * baseScore),
        "stage4.team.4.score": increment(fastestCount[3] * baseScore),
      });
    }
  };

  const undo = () => {
    if (stage4?.phase === "playing") {
      updatePhase("waiting");
    }
    if (stage4?.phase === "finished") {
      //出題開始前に戻す
      updatePhase("waiting");
    }
    if (stage4?.phase === "explaining") {
      updatePhase("finished");
    }
    if (stage4?.phase === "calculated") {
      updatePhase("explaining");
    }
    if (stage4?.phase === "waiting") {
      if (stage4?.probNumber === 1) {
        return;
      }
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stage4.probNumber": increment(-1),
        "stage4.phase": "calculated",
      });
    }
  };

  const updateOpen = (userId: string) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage4." + stage4?.probNumber + ".open"]: true,
    });
  };

  const updateCorrectStatus = (userId: string, correct: number) => {
    if (!roomId) return;
    if (!room) return;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage4." + stage4?.probNumber + ".correctStatus"]: correct,
    });
  };

  const updateNextProblem = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stage4) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stage4.probNumber": increment(1),
      "stage4.phase": "waiting",
    });
  };

  return (
    <>
      <h2>ステージ4</h2>
      <Button variant="contained" color="inherit" onClick={undo}>
        UNDO
      </Button>
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        <p>問題管理</p>
        {(stage4?.phase ?? "waiting") === "waiting" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("playing");
              }}
            >
              スタート
            </Button>
          </>
        ) : stage4?.phase === "playing" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("finished");
              }}
            >
              解答終了
            </Button>
          </>
        ) : stage4?.phase === "finished" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("explaining");
              }}
            >
              解説表示
            </Button>
          </>
        ) : stage4?.phase === "explaining" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("calculated");
              }}
            >
              得点集計
            </Button>
          </>
        ) : stage4?.phase === "calculated" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateNextProblem();
              }}
            >
              次の問題へ
            </Button>
          </>
        ) : (
          <></>
        )}
        <p>
          {getProblem4th(stage4?.probNumber ?? 1)
            ?.answer.map((ans, i) => `${i + 1}:${ans.join(",")}`)
            .join(" ")}
        </p>
      </div>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>1st</th>
          <th style={thStyle}>2nd</th>
          <th style={thStyle}>3rd</th>
          <th style={thStyle}>一括</th>
          <th style={thStyle}>スコア</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              {teamName[teamId]}
            </th>
            {[1, 2, 3].map((teamNumber) => (
              <td
                key={teamNumber}
                style={{
                  ...tdStyle,
                }}
              >
                {users
                  .filter(
                    (u) => u.teamId === teamId && u.teamNumber === teamNumber
                  )
                  .map((u, i) => {
                    const userSubmit = room.stage4?.probNumber
                      ? u.stage4?.[room.stage4?.probNumber]
                      : undefined;
                    const userAnswer = userSubmit?.answer ?? "";
                    const prob = getProblem4th(stage4?.probNumber ?? 1);
                    const answer = prob?.answer ?? [];
                    const answerTime =
                      stage4?.startTime && userSubmit?.answerTime
                        ? userSubmit.answerTime.toDate().getTime() -
                          (stage4.startTime as Timestamp).toDate().getTime()
                        : undefined;
                    return (
                      <>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#222",
                            color: "#fff",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            {userSubmit?.open ? "オープン" : "クローズ中"}
                          </p>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          『{userAnswer}』
                        </p>
                        {answerTime && (
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <span>
                              {(answerTime / 1000) | 0}.
                              {("00" + (((answerTime % 1000) / 10) | 0)).slice(
                                -2
                              )}
                              秒
                            </span>
                          </div>
                        )}

                        <p
                          key={i}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {u.name}
                        </p>
                        {fastest.findIndex((d) => d?.userId === u.id) !==
                          -1 && (
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {fastest.findIndex((d) => d?.userId === u.id) + 1}{" "}
                            最速
                          </p>
                        )}
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          <IconButton
                            aria-label="wrong"
                            color={
                              (
                                userSubmit?.correctStatus === undefined
                                  ? answer.some((e) =>
                                      e.some((ans) => ans === userAnswer)
                                    )
                                  : userSubmit.correctStatus !== -1
                              )
                                ? "inherit"
                                : "info"
                            }
                            onClick={() => {
                              updateCorrectStatus(u.id, -1);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <>
                            {[0, 1, 2, 3].map((status) => (
                              <IconButton
                                key={status}
                                aria-label="correct"
                                color={
                                  (
                                    userSubmit?.correctStatus === undefined
                                      ? answer.findIndex((e) =>
                                          e.some((ans) => ans === userAnswer)
                                        ) === status
                                      : userSubmit?.correctStatus === status
                                  )
                                    ? "error"
                                    : "inherit"
                                }
                                onClick={() => {
                                  updateCorrectStatus(u.id, status);
                                }}
                              >
                                {status + 1}
                              </IconButton>
                            ))}
                          </>
                        </p>
                        {!userSubmit?.open && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              updateOpen(u.id);
                            }}
                          >
                            オープン
                          </Button>
                        )}
                      </>
                    );
                  })}
              </td>
            ))}
            <td
              style={{
                ...tdStyle,
              }}
            >
              {users
                .filter((u) => u.teamId === teamId)
                .some((u) => {
                  const userSubmit = room.stage4?.probNumber
                    ? u.stage4?.[room.stage4?.probNumber]
                    : undefined;
                  return !userSubmit?.open;
                }) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    users
                      .filter((u) => u.teamId === teamId)
                      .forEach((u) => {
                        updateOpen(u.id);
                      });
                  }}
                >
                  オープン
                </Button>
              )}
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {stage4?.team[teamId]?.score ?? 0}
            </td>
          </tr>
        ))}
        <tr>
          <th style={tdStyle}>一括操作</th>
          <td style={tdStyle}></td>
          <td style={tdStyle}></td>
          <td style={tdStyle}></td>
          <td style={tdStyle}>
            {users.some((u) => {
              const userSubmit = room.stage4?.probNumber
                ? u.stage4?.[room.stage4?.probNumber]
                : undefined;
              return !userSubmit?.open;
            }) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  users.forEach((u) => {
                    updateOpen(u.id);
                  });
                }}
              >
                全員オープン
              </Button>
            )}
          </td>
          <th style={tdStyle}></th>
        </tr>
      </table>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
