import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import {
  doc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { getServerTime } from "../../../../lib/getServerTime";
import { getProblem1st } from "../../data/prob";
import { mainBg, mainColor } from "../../theme";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";
import { db } from "../../../..";
import finishedImage from "../../image/finished.png";

export const Stage1stPlayer: FC<StagePlayerProps> = (props) => {
  const { room, user, roomId, userId } = props;
  const stage1 = room?.stage1;
  const prob = getProblem1st(
    stage1?.probNumber ?? 1,
    user.teamNumber ?? 1,
    stage1?.genre ?? "ジャンル：国語"
  );

  const [serverTimeDiff, setServerTimeDiff] = useState<number>(0);
  const playerName = user?.name ?? "";
  const [serverTime, setServerTime] = useState<Date>();
  const start = stage1?.phase !== "waiting";
  const countDown = !start
    ? 30
    : stage1?.phase === "finished" ||
      stage1?.phase === "calculated" ||
      stage1?.phase === "explaining"
    ? 0
    : Math.max(
        0,
        (prob?.timeLimit ?? 30) -
          ((serverTime?.getTime() ?? 0) -
            (stage1?.startTime as Timestamp)?.toDate()?.getTime()) /
            1000
      );

  const explained =
    stage1?.phase === "explaining" || stage1?.phase === "calculated";
  const finished =
    stage1?.phase === "finished" ||
    stage1?.phase === "explaining" ||
    stage1?.phase === "calculated";

  useEffect(() => {
    if (!room || !user) {
      return;
    }
    if (!start) {
      return;
    }
    if (!playerName) {
      return;
    }
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start, playerName]);

  const [input, setInput] = useState(
    user.stage1?.[stage1?.genre ?? "ジャンル：国語"]?.[
      stage1?.probNumber ?? "1"
    ]?.answer ?? ""
  );
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (finished && !submitted) {
      setSubmitted(true);
      // 時間切れ時にsubmitする処理
      // 再アクセスしたときにsubmit済であれば投稿しない
      if (
        user.stage1?.[stage1?.genre ?? "ジャンル：国語"]?.[
          stage1?.probNumber ?? "1"
        ]?.answer
      ) {
        return;
      }
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
        ["stage1." + stage1?.genre + "." + stage1?.probNumber]: {
          answer: input,
          answerTime: serverTimestamp(),
        },
      });
    }
  }, [finished]);

  useEffect(() => {
    // phase: waiting時に初期化
    setInput(
      user.stage1?.[stage1?.genre ?? "ジャンル：国語"]?.[
        stage1?.probNumber ?? "1"
      ]?.answer ?? ""
    );
    if (!start) {
      setSubmitted(false);
    }
  }, [start]);

  const submit = () => {
    if (!roomId) return;
    if (!user) return;
    if (!prob) return;
    if (!start) return;
    if (finished) return;
    if (!serverTime) return;
    if (!input) return;
    if (!userId) return;
    setSubmitted(true);
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      ["stage1." + stage1?.genre + "." + stage1?.probNumber]: {
        answer: input,
        answerTime: serverTimestamp(),
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "8vw",
              fontWeight: "bold",
            }}
          >
            {prob?.problemNumberText}
          </p>
        </div>
      </Grid>

      <Grid item xs={8}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            height: "10vh",
            borderRadius: "4vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          <p
            style={{
              fontSize: "6vw",
              fontWeight: "bold",
              marginLeft: "2vw",
              marginRight: "auto",
            }}
          >
            {prob?.genre}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "2vw 0",
        }}
      >
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              color: mainColor,
              fontSize: "5vw",
              fontWeight: "bold",
              marginLeft: "2vw",
            }}
          >{`${("0" + Math.floor(countDown / 60)).slice(-2)}:${(
            "0" + Math.floor(countDown % 60)
          ).slice(-2)}`}</Typography>
        </Box>
        <Box sx={{ margin: 2, width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={(countDown / 30) * 100}
            sx={{
              height: "2vw",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "calc(100% - 4vw)",
            margin: "2vw",
            borderRadius: "2vw",
            backgroundColor: mainBg,
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          <img
            src={
              explained
                ? process.env.PUBLIC_URL +
                  "/images/nazo-cluster-quiz/onelineq/" +
                  prob?.answerFileName
                : finished
                ? finishedImage
                : !start
                ? process.env.PUBLIC_URL +
                  "/images/nazo-cluster-quiz/onelineq/" +
                  prob?.introFileName
                : process.env.PUBLIC_URL +
                  "/images/nazo-cluster-quiz/onelineq/" +
                  prob?.problemFileName
            }
            style={{
              width: "calc(100% - 4vw)",
              margin: "2vw",
            }}
            alt="問題画像"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          color: mainColor,
          fontSize: "2vw",
          fontWeight: "bold",
          textAlign: "center",
          margin: "2vw 0",
        }}
      >
        <p>制限時間{prob?.timeLimit}秒</p>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          color: mainColor,
          fontSize: "2vw",
          fontWeight: "bold",
          textAlign: "center",
          margin: "2vw 0",
        }}
      >
        <p>{prob?.answerMessage}</p>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          color: mainColor,
          fontSize: "2vw",
          fontWeight: "bold",
          textAlign: "center",
          margin: "2vw 0",
        }}
      >
        <Grid container>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              sx={{
                width: "calc(100% - 4vw)",
                height: "100%",
                margin: "0 2vw",
                bgcolor: "white",
              }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              disabled={!start || finished || submitted}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{
                margin: "0 2vw",
                width: "calc(100% - 4vw)",
                height: "100%",
                fontSize: "5vw",
                fontWeight: "bold",
              }}
              disabled={!start || finished || submitted}
              onClick={submit}
            >
              提出
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
