import { Box, Chip } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { CSSProperties, FC, useState } from "react";
import { db } from "../../../..";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";
import { teamName } from "../../data/teamName";

type UserTeam = {
  teamId: number;
  teamNumber: number;
};

export const UserRegister: FC<StageProps> = (props) => {
  const { users, roomId } = props;

  const [teamCursor, setTeamCursor] = useState<UserTeam | null>({
    teamId: 1,
    teamNumber: 1,
  });

  const updateUser = (userId: string, teamId: number, teamNumber: number) => {
    if (!roomId) return;
    const prevTeamId = users.find((u) => u.id === userId)?.teamId;
    const prevTeamNumber = users.find((u) => u.id === userId)?.teamNumber;
    updateDoc(doc(db, "nazoClusterQuiz/" + roomId + "/user/" + userId), {
      teamId: teamId === 0 ? null : teamId,
      teamNumber: teamNumber === 0 ? null : teamNumber,
    });
    if (
      teamId === 0 &&
      teamNumber === 0 &&
      prevTeamId !== null &&
      prevTeamNumber !== null
    ) {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        [`teams.${prevTeamId}.number.${prevTeamNumber}`]: null,
      });
    } else {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        [`teams.${teamId}.number.${teamNumber}.id`]: userId,
        [`teams.${teamId}.number.${teamNumber}.name`]: users.find(
          (u) => u.id === userId
        )?.name,
      });
    }

    // cursor increment
    const nextTeamNumber = teamNumber + 1;
    if (nextTeamNumber <= 3) {
      setTeamCursor({ teamId, teamNumber: nextTeamNumber });
    } else {
      const nextTeamId = teamId + 1;
      if (nextTeamId <= 4) {
        setTeamCursor({ teamId: nextTeamId, teamNumber: 1 });
      }
    }
  };

  return (
    <>
      <h2>ユーザー登録</h2>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>1st</th>
          <th style={thStyle}>2nd</th>
          <th style={thStyle}>3rd</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              {teamName[teamId]}
            </th>
            {[1, 2, 3].map((teamNumber) => (
              <td
                key={teamNumber}
                style={{
                  ...tdStyle,
                  ...(teamCursor?.teamId === teamId &&
                  teamCursor?.teamNumber === teamNumber
                    ? cursorStyle
                    : {}),
                }}
                onClick={() => {
                  setTeamCursor({ teamId, teamNumber });
                }}
              >
                {users
                  .filter(
                    (u) => u.teamId === teamId && u.teamNumber === teamNumber
                  )
                  .map((u) => (
                    <Chip
                      key={u.id}
                      label={u.name}
                      onClick={() => {
                        updateUser(u.id, 0, 0);
                      }}
                    />
                  ))}
              </td>
            ))}
          </tr>
        ))}
      </table>

      <Box
        sx={{
          my: 1,
          maxWidth: 1200,
          mx: "auto",
        }}
      >
        {users
          .filter(
            (u) =>
              !u.teamId || !u.teamNumber || u.teamId === 0 || u.teamNumber === 0
          )
          .map((user) => (
            <Chip
              key={user.id}
              label={user.name}
              onClick={() => {
                if (!teamCursor) return;
                updateUser(user.id, teamCursor.teamId, teamCursor.teamNumber);
              }}
            />
          ))}
      </Box>
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#eee",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
};

const cursorStyle: CSSProperties = {
  boxShadow: "0 0 0 2px #e89a00 inset",
};

const teamHeaderStyle = {
  color: "white",
};
