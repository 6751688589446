import first from "./1st.json";
import third from "./3rd.json";
import fourth from "./4th.json";
import final from "./final.json";

export type Problem1st = {
  problemNumber: number;
  playerId: number;
  problemNumberText: string;
  genre: string;
  introFileName: string;
  problemFileName: string;
  answerFileName: string;
  answerMessage: string;
  timeLimit: number;
  answer: string;
  score: number;
};

export type Problem3rd = {
  problemNumber: number;
  problemNumberText: string;
  genre: string;
  introFileName: string;
  problemFileName: string;
  answerFileName: string;
  answerMessage: string;
  timeLimit: number;
  answer: string;
  score: number;
};

export type Problem4th = {
  problemNumber: number;
  problemNumberText: string;
  genre: string;
  introFileName: string;
  problemFileName: string;
  answerFileName: string;
  answerMessage: string;
  timeLimit: number;
  answer: string[][];
  score: number;
};

export type ProblemFinal = {
  problemId: number;
  problemNumberText: string;
  genre: string;
  introFileName: string;
  answerMessage: string;
  timeLimit: number;
  answer: string;
  score: number;
};

export const firstProblemList: Problem1st[] = first.values
  .filter((e, i) => i > 0)
  .map((problem, index) => {
    return {
      problemNumber: parseInt(problem[0]),
      playerId: parseInt(problem[1]),
      problemNumberText: problem[2],
      genre: problem[3],
      introFileName: problem[4],
      problemFileName: problem[5],
      answerFileName: problem[6],
      answerMessage: problem[7],
      timeLimit: parseInt(problem[8]),
      answer: problem[9],
      score: parseInt(problem[10]),
    };
  });

export const thirdProblemList: Problem3rd[] = third.values
  .filter((e, i) => i > 0)
  .map((problem, index) => {
    return {
      problemNumber: parseInt(problem[0]),
      problemNumberText: problem[1],
      genre: problem[2],
      introFileName: problem[3],
      problemFileName: problem[4],
      answerFileName: problem[5],
      answerMessage: problem[6],
      timeLimit: parseInt(problem[7]),
      answer: problem[8],
      score: parseInt(problem[9]),
    };
  });

export const fourthProblemList: Problem4th[] = fourth.values
  .filter((e, i) => i > 0)
  .map((problem, index) => {
    return {
      problemNumber: parseInt(problem[0]),
      problemNumberText: problem[1],
      genre: problem[2],
      introFileName: problem[3],
      problemFileName: problem[4],
      answerFileName: problem[5],
      answerMessage: problem[6],
      timeLimit: parseInt(problem[7]),
      answer: problem[8].split(",").map((e) => e.split("/")),
      score: parseInt(problem[9]),
    };
  });

export const finalProblemList: ProblemFinal[] = final.values
  .filter((e, i) => i > 0)
  .map((problem, index) => {
    return {
      problemId: parseInt(problem[0]),
      problemNumberText: problem[1],
      genre: problem[2],
      introFileName: problem[3],
      answerMessage: problem[4],
      timeLimit: parseInt(problem[5]),
      answer: problem[6],
      score: parseInt(problem[7]),
    };
  });

export const getProblem1st = (
  problemNumber: number,
  playerId: number,
  genre: string
) => {
  return firstProblemList.find(
    (problem) =>
      problem.problemNumber === problemNumber &&
      problem.playerId === playerId &&
      problem.genre === genre
  );
};

export const getProblem3rd = (problemNumber: number) => {
  return thirdProblemList.find(
    (problem) => problem.problemNumber === problemNumber
  );
};

export const getProblem4th = (problemNumber: number) => {
  return fourthProblemList.find(
    (problem) => problem.problemNumber === problemNumber
  );
};

export const getProblemFinal = (problemId: number) => {
  return finalProblemList.find((problem) => problem.problemId === problemId);
};
