import { Grid } from "@mui/material";
import { FC, useMemo } from "react";
import { MemberLabel } from "./MemberLabel";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";

type TeamScreenProps = {
  teamName: string;
  teamColor: string;
  teamId: number;
} & StagePlayerProps;

export const TeamViewer: FC<TeamScreenProps> = (props) => {
  const { teamName, teamColor, room, teamId } = props;
  const playerNames = useMemo(
    () =>
      [1, 2, 3].map((id) => room.teams?.[teamId]?.number?.[id]?.name ?? "　"),
    [room.teams, teamId]
  );

  return (
    <div
      style={{
        margin: "4vw 2vw",
        height: "20dvh",
        width: "96vw",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              border: `0.5vw solid ${teamColor}`,
              margin: "0 1vw",
              backgroundColor: teamColor,
              color: "white",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <p
              style={{
                margin: "0 2vw",
                fontSize: "10vw",
              }}
            >
              {teamName}
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ my: "2vh" }}>
        {[1, 2, 3].map((i) => (
          <Grid item xs={4} key={i}>
            <MemberLabel
              mode={"blue"}
              name={playerNames[i - 1]}
              cursor={false}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
