import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import background from "../image/back-5_.png";
import { blue, green, red, yellow } from "../theme";
import { NazoClusterQuizRoom, NazoClusterQuizUser } from "../types";
import { Stage1stScreen } from "./components/Stage1stScreen";
import { Stage2ndScreen } from "./components/Stage2ndScreen";
import { Stage3rdScreen } from "./components/Stage3rdScreen";
import { Stage4thScreen } from "./components/Stage4thScreen";
import { StageFinalScreen } from "./components/StageFinalScreen";
import { teamName } from "../data/teamName";
import { ResultsScreen } from "./components/ResultsScreen";
import { Register } from "./components/Register";

export const teams = [
  {
    teamName: teamName[1],
    teamColor: red,
  },
  {
    teamName: teamName[2],
    teamColor: blue,
  },
  {
    teamName: teamName[3],
    teamColor: green,
  },
  {
    teamName: teamName[4],
    teamColor: yellow,
  },
];

export const NazoClusterQuizScreen: FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const [room, setRoom] = useDoc<NazoClusterQuizRoom>({
    path: "nazoClusterQuiz/" + roomId,
    initialData: {
      mode: "waiting",
      setId: "default",
    },
  });
  const setProps = useMemo(
    () => ({
      path: roomId ? "nazoClusterQuiz/" + roomId + "/user" : null,
    }),
    [roomId]
  );
  const { docList: usersDoc, dataList: users } =
    useCollection<NazoClusterQuizUser>(setProps);

  if (!room || !roomId) {
    return <></>;
  }
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        height: "100dvh",
      }}
    >
      {room.mode === "register" && <Register roomId={roomId} />}
      {room.mode === "1st" && <Stage1stScreen room={room} users={users} />}
      {room.mode === "2nd" && <Stage2ndScreen room={room} />}
      {room.mode === "3rd" && <Stage3rdScreen room={room} users={users} />}
      {room.mode === "4th" && <Stage4thScreen room={room} users={users} />}
      {room.mode === "final" && <StageFinalScreen room={room} />}
      {room.mode === "results" && <ResultsScreen room={room} />}
    </div>
  );
};
