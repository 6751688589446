import { Box, Button } from "@mui/material";
import {
  deleteField,
  doc,
  increment,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { FC } from "react";
import { db } from "../../../..";
import { teamName } from "../../data/teamName";
import { blue, green, red, yellow } from "../../theme";
import { StageProps } from "../AdminNazoClusterQuiz";

type ProbCursor = {
  genre: number;
  probNumber: number;
};

export const StageFinal: FC<StageProps> = (props) => {
  const { users, roomId, room } = props;
  const stageFinal = room.stageFinal;

  const updateNextProblem = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.probNumber": increment(1),
      "stageFinal.phase": "waiting",
      "stageFinal.openFastest": false,
      "stageFinal.open": false,
    });
  };

  const updatePhase = (phase: string) => {
    if (!roomId) return;
    if (!room) return;

    if (phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": phase,
        "stageFinal.startTime": serverTimestamp(),
      });
    }
    if (phase === "finished") {
      const answers = users.map((user) => {
        return {
          teamId: user.teamId,
          teamNumber: user.teamNumber,
          answer:
            user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answer ?? null,
          answerTime:
            user.stageFinal?.[stageFinal?.probNumber ?? "1"]?.answerTime ??
            null,
        };
      });
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": phase,
        "stageFinal.endTime": serverTimestamp(),
        [`stageFinal.problem.${stageFinal?.probNumber ?? "1"}.answers`]:
          answers,
      });
    }
    if (phase === "explaining") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": phase,
      });
    }
  };

  const updateCorrect = (teamId: number, correct: boolean) => {
    if (!roomId) return;
    if (!stageFinal) return;
    const newScores = [1, 2, 3, 4].map((team) =>
      team === teamId
        ? correct
          ? stageFinal.team[team].score
          : stageFinal.team[team].score <= 20
          ? 0
          : Math.ceil(stageFinal.team[team].score / 2)
        : correct
        ? Math.max(0, stageFinal.team[team].score - 20)
        : stageFinal.team[team].score
    );
    const rank = newScores.filter((score) => score > 0).length + 1;
    const retiredTeamIds = [1, 2, 3, 4].filter(
      (team) => stageFinal.team[team].score !== 0 && newScores[team - 1] === 0
    );
    const updateData: { [key: string]: any } = {
      "stageFinal.team.1.score": newScores[0],
      "stageFinal.team.2.score": newScores[1],
      "stageFinal.team.3.score": newScores[2],
      "stageFinal.team.4.score": newScores[3],
      "stageFinal.team.1.prevScores":
        stageFinal.team[1].prevScores === undefined
          ? [stageFinal.team[1].score]
          : [...stageFinal.team[1].prevScores, stageFinal.team[1].score],
      "stageFinal.team.2.prevScores":
        stageFinal.team[2].prevScores === undefined
          ? [stageFinal.team[2].score]
          : [...stageFinal.team[2].prevScores, stageFinal.team[2].score],
      "stageFinal.team.3.prevScores":
        stageFinal.team[3].prevScores === undefined
          ? [stageFinal.team[3].score]
          : [...stageFinal.team[3].prevScores, stageFinal.team[3].score],
      "stageFinal.team.4.prevScores":
        stageFinal.team[4].prevScores === undefined
          ? [stageFinal.team[4].score]
          : [...stageFinal.team[4].prevScores, stageFinal.team[4].score],
      ["stageFinal.problem." + stageFinal.probNumber]: {
        teamId,
        correct,
        answers: stageFinal.problem?.[stageFinal.probNumber ?? "1"]?.answers,
      },
    };
    if (retiredTeamIds.length > 0) {
      retiredTeamIds.forEach((teamId) => {
        updateData["stageFinal.team." + teamId + ".rank"] = rank;
      });
    }
    if (newScores.filter((score) => score > 0).length === 1) {
      const winnerTeamId = newScores.findIndex((score) => score > 0) + 1;
      updateData["stageFinal.team." + winnerTeamId + ".rank"] = 1;
    }

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), updateData);
  };

  const updateOpenFastest = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.openFastest": true,
    });
  };

  const updateOpen = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.open": true,
    });
  };

  const undoScore = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;
    if (
      stageFinal.team[1].prevScores === undefined ||
      stageFinal.team[2].prevScores === undefined ||
      stageFinal.team[3].prevScores === undefined ||
      stageFinal.team[4].prevScores === undefined ||
      (stageFinal.team[1].prevScores?.length ?? 0) === 0 ||
      (stageFinal.team[2].prevScores?.length ?? 0) === 0 ||
      (stageFinal.team[3].prevScores?.length ?? 0) === 0 ||
      (stageFinal.team[4].prevScores?.length ?? 0) === 0 ||
      stageFinal.problem?.[stageFinal.probNumber ?? "1"] === undefined
    ) {
      return;
    }

    updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
      "stageFinal.team.1.score": stageFinal.team[1].prevScores.at(-1),
      "stageFinal.team.2.score": stageFinal.team[2].prevScores.at(-1),
      "stageFinal.team.3.score": stageFinal.team[3].prevScores.at(-1),
      "stageFinal.team.4.score": stageFinal.team[4].prevScores.at(-1),
      "stageFinal.team.1.prevScores": stageFinal.team[1].prevScores.slice(
        0,
        -1
      ),
      "stageFinal.team.2.prevScores": stageFinal.team[2].prevScores.slice(
        0,
        -1
      ),
      "stageFinal.team.3.prevScores": stageFinal.team[3].prevScores.slice(
        0,
        -1
      ),
      "stageFinal.team.4.prevScores": stageFinal.team[4].prevScores.slice(
        0,
        -1
      ),
      ["stageFinal.problem." + stageFinal.probNumber + ".correct"]:
        deleteField(),
      ["stageFinal.problem." + stageFinal.probNumber + ".teamId"]:
        deleteField(),
    });
  };

  const undo = () => {
    if (!roomId) return;
    if (!room) return;
    if (!stageFinal) return;
    if (stageFinal?.phase === "waiting") {
      if (stageFinal?.probNumber === 1) {
        return;
      }
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.probNumber": increment(-1),
      });
    }
    if (stageFinal?.phase === "playing") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": "waiting",
      });
    }
    if (stageFinal?.phase === "finished") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": "playing",
      });
      undoScore();
    }
    if (stageFinal?.phase === "explaining") {
      updateDoc(doc(db, "nazoClusterQuiz/" + roomId), {
        "stageFinal.phase": "finished",
      });
    }
  };

  if (!stageFinal) {
    return <Box>loading...</Box>;
  }

  return (
    <>
      <h2>ステージFinal</h2>
      <Button
        variant="contained"
        color="inherit"
        onClick={() => {
          undo();
        }}
      >
        UNDO
      </Button>
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        <p>問題管理</p>
        {(stageFinal?.phase ?? "waiting") === "waiting" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("playing");
              }}
            >
              スタート
            </Button>
          </>
        ) : stageFinal.phase === "playing" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("finished");
              }}
            >
              解答終了
            </Button>
          </>
        ) : stageFinal?.phase === "finished" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updatePhase("explaining");
              }}
            >
              ランキング表示(解答以外)
            </Button>
          </>
        ) : stageFinal?.phase === "explaining" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateNextProblem();
            }}
          >
            次の問題へ
          </Button>
        ) : (
          <></>
        )}
      </div>
      <table
        style={{
          margin: "auto",
          border: "1px solid #000",
          borderCollapse: "collapse",
          borderSpacing: 0,
          fontSize: "100%",
        }}
      >
        <tr style={trStyle}>
          <th style={thStyle}>チーム</th>
          <th style={thStyle}>スコア</th>
          <th style={thStyle}>スコア操作</th>
        </tr>
        {[1, 2, 3, 4].map((teamId) => (
          <tr key={teamId}>
            <th
              style={{
                ...thStyle,
                ...teamHeaderStyle,
                backgroundColor: [red, blue, green, yellow][teamId - 1],
              }}
            >
              {teamName[teamId]}
            </th>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {(stageFinal.team[teamId]?.score ?? 0) === 0
                ? "脱落"
                : stageFinal.team[teamId]?.score ?? 0}
            </td>
            <td
              style={{
                ...tdStyle,
              }}
            >
              {stageFinal.problem?.[stageFinal.probNumber ?? "1"]?.teamId ? (
                <>
                  {stageFinal.problem?.[stageFinal.probNumber ?? "1"].teamId ===
                    teamId && (
                    <>
                      <p>
                        {stageFinal.problem?.[stageFinal.probNumber ?? "1"]
                          .correct
                          ? "正解"
                          : "不正解"}
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          undoScore();
                        }}
                        disabled={
                          (stageFinal.team[1].prevScores?.length ?? 0) === 0 ||
                          (stageFinal.team[2].prevScores?.length ?? 0) === 0 ||
                          (stageFinal.team[3].prevScores?.length ?? 0) === 0 ||
                          (stageFinal.team[4].prevScores?.length ?? 0) === 0 ||
                          stageFinal.problem?.[stageFinal.probNumber ?? "1"]
                            ?.correct === undefined
                        }
                      >
                        正誤判定やり直し
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateCorrect(teamId, true);
                    }}
                  >
                    正解
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateCorrect(teamId, false);
                    }}
                    sx={{ ml: 2 }}
                  >
                    不正解
                  </Button>
                </>
              )}
            </td>
          </tr>
        ))}
      </table>
      <Box
        sx={{
          textAlign: "center",
          mt: 2,
          display: "flex",
          direction: "row",
          justifyContent: "center",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateOpenFastest();
            }}
            disabled={stageFinal.openFastest}
          >
            最速解答をオープン
          </Button>
          {stageFinal.openFastest && <p>オープン済</p>}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateOpen();
            }}
            sx={{ ml: 2 }}
            disabled={stageFinal.open}
          >
            その他の解答をオープン
          </Button>
          {stageFinal.open && <p>オープン済</p>}
        </Box>
      </Box>

      <Box>
        {users
          .map((user) => {
            return {
              user,
              userProb: user.stageFinal?.[stageFinal.probNumber ?? "1"],
            };
          })
          .sort(({ userProb: a }, { userProb: b }) => {
            return !a?.answerTime && !b?.answerTime
              ? 0
              : !a?.answerTime
              ? 1
              : !b?.answerTime
              ? -1
              : (a?.answerTime as Timestamp)?.toDate().getTime() -
                (b?.answerTime as Timestamp)?.toDate().getTime();
          })
          .map(({ user, userProb }, i) => {
            const startTime = (stageFinal?.startTime as Timestamp | undefined)
              ?.toDate()
              .getTime();
            const answerTime = (userProb?.answerTime as Timestamp | undefined)
              ?.toDate()
              .getTime();
            return (
              <Box key={user.name}>
                {user.teamId && (
                  <p>
                    {answerTime ? i + 1 : "---"}. {teamName[user.teamId ?? 0]}{" "}
                    {user.name}: {userProb?.answer}
                    {startTime && answerTime ? (
                      <span>
                        {" "}
                        {((answerTime - startTime) / 1000) | 0}.
                        {(
                          "00" +
                          ((((answerTime - startTime) % 1000) / 10) | 0)
                        ).slice(-2)}
                        秒
                      </span>
                    ) : (
                      <span>--- 秒</span>
                    )}
                  </p>
                )}
              </Box>
            );
          })}
      </Box>
      <Box sx={{ my: 8 }} />
    </>
  );
};

const trStyle = {
  border: "1px solid #000",
};

const thStyle = {
  border: "1px solid #000",
  backgroundColor: "#444",
  color: "white",
};

const tdStyle = {
  border: "1px solid #000",
  minWidth: "100px",
  color: "#111",
};

const teamHeaderStyle = {
  color: "white",
};
