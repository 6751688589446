import { FC } from "react";

type MemberScreenProps = {
  cursor?: boolean;
  mode: "blue" | "red" | "normal";
  name: string;
};

const theme = {
  blue: {
    backgroundColor: "blue",
    color: "white",
  },
  red: {
    backgroundColor: "red",
    color: "white",
  },
  normal: {
    backgroundColor: "white",
    color: "#111",
  },
};

export const MemberLabel: FC<MemberScreenProps> = (props) => {
  const { cursor, mode, name } = props;
  const styles = theme[mode];
  return (
    <div
      style={{
        margin: " 0 1vw",
      }}
    >
      <div
        style={{
          fontSize: "1.2em",
          border: `1vw solid ` + (cursor ? "blue" : "#9eeef5"),
          backgroundColor: mode === "normal" ? "#444" : "#9eeef5",
          color: "#111",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            fontSize: "1em",
            margin: 0,
          }}
        >
          {name}
        </h2>
      </div>
    </div>
  );
};
