import { ReactNode } from "react";
import "./wrapper.css";

// コンテンツ事のcssなどを当てる

export const NazoClusterQuizGameWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <>{children}</>;
};
