import { Grid } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { MemberLabel } from "./MemberLabel";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";
import CountUp from "react-countup";

type TeamScreenProps = {
  teamName: string;
  teamColor: string;
  teamId: number;
} & StagePlayerProps;

export const TeamSelector: FC<TeamScreenProps> = (props) => {
  const { teamName, teamColor, room, teamId } = props;
  const [prevTotalScore, setPrevTotalScore] = useState(
    room.stage2?.team[teamId].score ?? 0
  );
  const [currentTotalScore, setCurrentTotalScore] = useState(
    room.stage2?.team[teamId].score ?? 0
  );
  useEffect(() => {
    if (currentTotalScore !== (room.stage2?.team[teamId].score ?? 0)) {
      setPrevTotalScore(currentTotalScore);
      setCurrentTotalScore(room.stage2?.team[teamId].score ?? 0);
    }
  }, [room.stage2?.team[teamId].score]);

  const playerNames = useMemo(
    () => [1, 2, 3].map((id) => room.teams?.[teamId]?.number?.[id]?.name ?? ""),
    [room.teams, teamId]
  );
  if (playerNames.every((name) => name === "")) {
    return (
      <div
        style={{
          margin: "4vw 2vw",
          height: "20dvh",
          width: "96vw",
        }}
      />
    );
  }
  return (
    <div
      style={{
        margin: "4vw 2vw",
        height: "20dvh",
        width: "96vw",
      }}
    >
      <Grid container>
        <Grid item xs={7}>
          <div
            style={{
              border: `0.5vw solid ${teamColor}`,
              margin: "0 1vw",
              backgroundColor: teamColor,
              color: "white",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <p
              style={{
                margin: "0 2vw",
                fontSize: "10vw",
              }}
            >
              {teamName}
            </p>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div
            style={{
              border: `0.5vw solid ${teamColor}`,
              backgroundColor: "white",
              margin: "0 1vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            <p
              style={{
                textAlign: "right",
                verticalAlign: "bottom",
                marginTop: 0,
                marginLeft: "auto",
                paddingLeft: "auto",
                marginRight: "2vw",
                marginBottom: "0",
                fontSize: "10vw",
                fontWeight: "bold",
              }}
            >
              <CountUp
                start={prevTotalScore}
                end={currentTotalScore}
                duration={0.5}
              />{" "}
              <span
                style={{
                  // 小さくして右下に寄せて表示
                  fontSize: "5vw",
                }}
              >
                点
              </span>
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ my: "2vh" }}>
        <Grid item xs={4}>
          <MemberLabel
            mode={
              room.stage2?.wrongUser?.[teamId + "-" + 1] ? "normal" : "blue"
            }
            name={room.teams?.[teamId]?.number?.[1]?.name ?? "　"}
            cursor={
              ((((room.stage2?.probNumber ?? 1) - 1) / 4) | 0) % 3 === 1 - 1 &&
              ((room.stage2?.probNumber ?? 1) - 1) % 4 === teamId - 1
            }
          />
        </Grid>
        <Grid item xs={4}>
          <MemberLabel
            mode={
              room.stage2?.wrongUser?.[teamId + "-" + 2] ? "normal" : "blue"
            }
            name={room.teams?.[teamId]?.number?.[2]?.name ?? "　"}
            cursor={
              ((((room.stage2?.probNumber ?? 1) - 1) / 4) | 0) % 3 === 2 - 1 &&
              ((room.stage2?.probNumber ?? 1) - 1) % 4 === teamId - 1
            }
          />
        </Grid>
        <Grid item xs={4}>
          <MemberLabel
            mode={
              room.stage2?.wrongUser?.[teamId + "-" + 3] ? "normal" : "blue"
            }
            name={room.teams?.[teamId]?.number?.[3]?.name ?? "　"}
            cursor={
              ((((room.stage2?.probNumber ?? 1) - 1) / 4) | 0) % 3 === 3 - 1 &&
              ((room.stage2?.probNumber ?? 1) - 1) % 4 === teamId - 1
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
