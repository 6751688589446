import { Grid } from "@mui/material";
import { FC, useMemo } from "react";
import { teams } from "../../screen/NazoClusterQuizScreen";
import { StagePlayerProps } from "../NazoClusterQuizPlayer";
import { mainColor } from "../../theme";

export const ResultsPlayer: FC<StagePlayerProps> = (props) => {
  const { room, user, roomId, userId } = props;
  const names = useMemo(
    () =>
      [1, 2, 3, 4].map((teamId) =>
        [1, 2, 3].map(
          (playerId) => room.teams?.[teamId]?.number?.[playerId]?.name ?? ""
        )
      ),
    [room.teams]
  );
  const rankOrder = useMemo(() => {
    const order = [1, 2, 3, 4].map((teamId) => {
      return {
        teamId: teamId,
        rank: room.stageFinal?.team[teamId].rank,
      };
    });
    return order.sort((a, b) =>
      a.rank === undefined
        ? b.rank === undefined
          ? 0
          : 100
        : b.rank === undefined
        ? -100
        : a.rank - b.rank
    );
  }, [room.teams]);

  console.log("rankOrder", rankOrder);

  return (
    <div
      style={{
        height: "100dvh",
      }}
    >
      <div style={{ height: "1vh", textAlign: "center" }} />
      <div style={{ height: "10vh", textAlign: "center" }}>
        <h1
          style={{
            margin: "0",
            color: mainColor,
          }}
        >
          {new Date().getMonth() + 1}月{new Date().getDate()}日 結果
        </h1>
      </div>
      {rankOrder.map((rankdata, i) => (
        <Grid container key={i}>
          <Grid item xs={12}>
            <TeamResultView
              teamName={teams[rankdata.teamId - 1].teamName}
              teamColor={teams[rankdata.teamId - 1].teamColor}
              teamId={rankdata.teamId}
              names={names[rankdata.teamId - 1]}
              rank={rankdata.rank ?? 0}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const rankName = ["優勝", "2位", "3位", "4位"];

const TeamResultView: FC<{
  teamName: string;
  teamColor: string;
  teamId: number;
  names: string[];
  rank: number;
}> = ({ teamName, teamColor, teamId, names, rank }) => {
  if (names.every((name) => name === "")) {
    return <div style={{ width: "75vw", margin: "auto" }} />;
  }
  return (
    <Grid
      container
      spacing={1}
      sx={{
        width: "80vw",
        margin: "auto",
        mb: 1,
      }}
    >
      <Grid item xs={5}>
        <div
          style={{
            margin: "0.5vw",
          }}
        >
          <h1
            style={{
              fontSize: "2em",
              margin: "8px 0px",
              color: mainColor,
            }}
          >
            {rankName[rank - 1] ?? "-位"}
          </h1>
        </div>
      </Grid>
      <Grid item xs={7}>
        <div
          style={{
            border: `0.5vw solid ${teamColor}`,
            margin: "auto",
            backgroundColor: teamColor,
            color: "white",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.5em", margin: "8px" }}>{teamName}</h1>
        </div>
      </Grid>
      {names.map((name) => (
        <Grid
          item
          xs={4}
          spacing={1}
          key={name}
          sx={{
            borderRadius: "10px",
            backgroundColor: "white",
            border: `0.5vw solid ${teamColor}`,
          }}
        >
          <p
            style={{
              fontSize: "1em",
              marginTop: "1px",
              marginBottom: "4px",
            }}
          >
            {name}
          </p>
        </Grid>
      ))}
    </Grid>
  );
};
